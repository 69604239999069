import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar-portable';
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ColorSwitcherComponent } from './color-switcher/color-switcher.component';


import $ from 'jquery';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { HttpLoaderFactory } from "../app.module";
import { OrderByPipe } from "./pipes/order-by.pipe";
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { ToastsContainer } from './ngb/toast-container';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ColorSwitcherComponent,
        NgbModule,
        OrderByPipe,
        Nl2brPipe,
        ToastsContainer
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        NgbDropdownModule,
        PerfectScrollbarModule,
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            useDefaultLang: true,
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ColorSwitcherComponent,
        OrderByPipe,
        Nl2brPipe,
        ToastsContainer
    ],
    providers: [ ],
})
export class SharedModule { }
